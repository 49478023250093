import axios from "axios";
import { FIREBASE_API_KEY } from "../../firebase/firebaseConfig";
import Swal from "sweetalert2";

const SIGNUP_URL = `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${FIREBASE_API_KEY}`;
const SEND_VERIFICATION_URL = `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${FIREBASE_API_KEY}`;


const handleSuccessPopup = () => {
  Swal.fire({
    title: 'Account Created Successfully',
    text: 'A Verification link has been sent to your email after verification, Please continue from the application.',
    icon: 'success',
    showCancelButton: true,
    confirmButtonText: 'Copy Link',
    cancelButtonText: 'Download Now',
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      const link = 'https://drive.google.com/file/d/1oX_qyxF7ckq-Vx6PxY4-bk9wuffyI_gl/view?usp=sharing';
      navigator.clipboard.writeText(link);
      Swal.fire('Copied!', 'The link has been copied to your clipboard.', 'success');
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      const downloadLink = 'https://drive.google.com/file/d/1oX_qyxF7ckq-Vx6PxY4-bk9wuffyI_gl/view?usp=sharing';
      window.open(downloadLink, '_blank');
    }
  });
};

export const useAuth = () => {
  const signUp = async (email, password) => {
    const { data } = await axios.post(SIGNUP_URL, {
      email,
      password,
      returnSecureToken: true,
    });

    console.log("Signup successful:", data);

    await sendVerificationEmail(data.idToken);
  };

  const sendVerificationEmail = async (idToken) => {
    try {
      await axios.post(SEND_VERIFICATION_URL, {
        requestType: "VERIFY_EMAIL",
        idToken,
      });
      console.log("Verification email sent");
    } catch (err) {
      throw new Error(err);
    }
  };

  return { signUp, handleSuccessPopup };
};
