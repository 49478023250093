import { useState } from "react";

const FAQ = ({ headerTitle, faqList, id }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div id={id} className="w-full max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-gray-900 text-center mb-8">
        {headerTitle}
      </h2>
      <div className="space-y-4">
        {faqList.map((faq, index) => (
          <div
            key={index}
            className="border-b border-gray-200 overflow-hidden"
          >
            <button
              onClick={() => toggleFAQ(index)}
              className="w-full text-left flex justify-between items-center p-6 text-gray-800 font-medium hover:bg-gray-100 transition"
            >
              {faq.question}
              <span className={`transform transition-transform ${openIndex === index ? "rotate-180" : "rotate-0"}`}>
                ▼
              </span>
            </button>
            <div
              className={`transition-all duration-300 ease-in-out overflow-hidden ${openIndex === index ? "h-auto mt-4 opacity-100" : "max-h-0 opacity-0"}`}
            >
              <p className="px-6 pb-6 text-gray-600">{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
