import { motion } from "framer-motion";
import { useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import BecomePartner from "../../screens/become-partner";

const BecomeAPartner = () => {
  const [isAddForm, setIsAddForm] = useState(false);
  return (
    <>
      <motion.div
        onClick={() => setIsAddForm(!isAddForm)}
        className="bg-white px-5 py-1 rounded-lg items-center flex gap-3 cursor-pointer w-fit"
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 100,
          damping: 15,
          duration: 0.8,
          delay: 0.2,
        }}
      >
        <div className="p-1 rounded-full bg-blue-600">
          <BsChevronLeft className="text-white" />
        </div>
        <p className="font-medium text-lg">Become a partner 🤝</p>
      </motion.div>
      <BecomePartner 
        isVisible={isAddForm}
        onClose={()=>setIsAddForm(false)}
      />
    </>
  );
};

export default BecomeAPartner;
