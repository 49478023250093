import React from "react";

function PWEPrivacy() {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-10">
      <h1 className="text-2xl font-bold text-center mb-4">Privacy Policy</h1>
      <p className="text-gray-600 mb-4">
        This privacy policy applies to the PWEWorkshop app ("Application") for
        mobile devices that was created by the Service Provider as a Free
        service. This service is intended for use "AS IS".
      </p>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Information Collection and Use</h2>
        <p className="text-gray-600 mb-2">The Application collects information such as:</p>
        <ul className="list-disc list-inside text-gray-600 space-y-1">
          <li>Your device's Internet Protocol address (IP address)</li>
          <li>Pages visited, time spent, and timestamps</li>
          <li>The operating system on your mobile device</li>
        </ul>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Location Data</h2>
        <p className="text-gray-600 mb-2">
          The Application collects your device's location to provide services
          like:
        </p>
        <ul className="list-disc list-inside text-gray-600 space-y-1">
          <li>Personalized content & recommendations</li>
          <li>Analytics and service improvements</li>
          <li>Sharing anonymized location data with trusted third-party services</li>
        </ul>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Third-Party Access</h2>
        <p className="text-gray-600">
          The Application may use third-party services that have their own
          privacy policies. This includes Google Play Services.
        </p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Opt-Out Rights</h2>
        <p className="text-gray-600">
          You can stop all data collection by uninstalling the Application.
        </p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Children's Privacy</h2>
        <p className="text-gray-600">
          The Service Provider does not knowingly collect data from children
          under 13 years old. If discovered, such data will be deleted
          immediately.
        </p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Security</h2>
        <p className="text-gray-600">
          We take reasonable security measures to protect your data from
          unauthorized access.
        </p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Changes to Privacy Policy</h2>
        <p className="text-gray-600">
          This Privacy Policy may be updated periodically. Please review it
          regularly.
        </p>
      </section>
      
      <section className="text-center mt-6">
        <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
        <p className="text-gray-600">Email: customercare@presswalaengineer.com</p>
      </section>
    </div>
  );
}

export default PWEPrivacy;
