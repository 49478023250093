import { motion, AnimatePresence } from "framer-motion";
import { TextField, Button, Checkbox, FormControlLabel } from "@mui/material";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useForm } from "./hook";

export default function VendorOnBoardingForm({ isVisible, onClose }) {
  const { formData, errors, handleChange, handleSubmit, loading } = useForm({
    onClose,
  });

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-5"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
        >
          <motion.div
            className="
              bg-white p-8 rounded-2xl shadow-xl w-full max-w-md space-y-6 
              relative border border-gray-200
            "
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -50, opacity: 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={onClose}
              className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
            >
              ✕
            </button>

            <h2 className="text-blue-600 text-2xl font-bold text-center">
              Vendor Details
            </h2>

            <form onSubmit={handleSubmit} className="space-y-5">
              <TextField
                label="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                fullWidth
                className="focus:ring-blue-400"
              />

              <TextField
                label="Password"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                error={!!errors.password}
                helperText={errors.password}
                fullWidth
                className="focus:ring-blue-400"
              />

              <TextField
                label="Vendor Name"
                name="vendorName"
                value={formData.vendorName}
                onChange={handleChange}
                error={!!errors.vendorName}
                helperText={errors.vendorName}
                fullWidth
                className="focus:ring-blue-400"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.agreement}
                    onChange={handleChange}
                    name="agreement"
                    color="primary"
                  />
                }
                label={
                  <a
                    href="/pwe-terms-and-condition"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 underline"
                    onClick={(e) => e.stopPropagation()}
                  >
                    I agree to the terms and conditions
                  </a>
                }
                className="text-gray-600"
              />
              {errors.agreement && (
                <p className="text-red-500 text-sm mt-1">{errors.agreement}</p>
              )}

              <Button
                type="submit"
                fullWidth
                style={{
                  backgroundColor: "#8A6859",
                  color: "white",
                }}
                className="flex items-center justify-center gap-3"
              >
                Submit
                {loading && (
                  <AiOutlineLoading3Quarters
                    className="animate-spin"
                    color="white"
                    size={18}
                  />
                )}
              </Button>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
