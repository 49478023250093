import IroningServices from "../assets/ourServices/ironingServices.png";
import DryCleanServices from "../assets/ourServices/dryCleanServices.png";
import PerfumeConditioningServices from "../assets/ourServices/perfumeConditioning.png";
import SofaService from "../assets/ourServices/sofa-service.png";
import InnovativePackagingServices from "../assets/ourServices/packaging.png";
import DelicateFabricServices from "../assets/ourServices/delicateFibre.png";
import GoogleImg from "../assets/google.png";
import AppleImg from "../assets/apple.png";
export const HomeContent = {
  wlcMsg: {
    welcome:'WELCOME',
    toPresswala: 'TO PRESSWALA',
    engineer: 'ENGINEER'
  },
  subHeadAboutMsg: {
    wash: "Washing",
    ironing: " Ironing",
    dryClean: " DryClean",
  },
  subCenterMsg:"The Best Laundry Service Solution Aggregator",
  aboutMsg: "Presswala Engineer – Your go-to app for finding top-rated Dry-Cleaning, and Laundry services! Easily compare nearby vendors, check ratings, and get the best deal—all at your fingertips. Download now for hassle-free service at your doorstep!",
  whyChoose: {
    title: "Why Choose Us?",
    options: [
      { id: 1, label: "Top Service from Top Vendor" },
      { id: 2, label: "Eco-Friendly Drycleaning Solutions" },
      { id: 3, label: "100% Satisfaction Guaranteed" },
      { id: 4, label: "Lowest Cost Promise" },
    ],
  },
  ourServices: {
    title: "Our Services",
    services: [
      {
        id: 1,
        label: "Ironing\nServices",
        bgColor: "#FCADDF",
        img: IroningServices,
      },
      {
        id: 2,
        label: "Dry Cleaning",
        bgColor: "#1193FF",
        img: DryCleanServices,
      },
      {
        id: 3,
        label: "Laundry service",
        bgColor: "#F7E8CB",
        img: PerfumeConditioningServices,
      },
      {
        id: 4,
        label: "Sofa Dryclean",
        bgColor: "#0AE3C4",
        img: SofaService,
      },
      {
        id: 5,
        label: "Professional services for soft toys",
        bgColor: "#B4EA27",
        img: InnovativePackagingServices,
      },
      {
        id: 6,
        label: "Curtains & Carpet Cleaning",
        bgColor: "#C62E46",
        img: DelicateFabricServices,
      },
    ],
  },
  contactNumber: "+91-8800443220",
  pureNumber: "8800443220",
  whatsAppNumber: "+91-8882792728",
  pureWhatsAppNumber: "918882792728",
  googleImg: GoogleImg,
  appleImg: AppleImg,
  playStoreUrl:
    "https://play.google.com/store/apps/details?id=com.presswalaengineer",
  appleStoreUrl:
    "https://apps.apple.com/in/app/presswala-engineer/id6743720359",
};

export const FooterContent = {
  header: "Presswala Engineer",
  desc: "Join Presswala Engineer today and take your dry-cleaning business to the next level!",
  office: {
    header: "Postal Mail",
    desc: "Presswala Engineer, Sikanderpur Village, Sector 85, Sihi, Gurugram, Haryana 122004",
    mob: HomeContent.contactNumber,
    email: "CustomerCare@presswalaengineer.com",
  }
};

export const TestimonialContent = {
  headers: "",
  allTestimonial: [
    {
      id: 1,
      testimonial:
        "I absolutely love using Presswala Engineer! Finding laundry services for my special garments was never this easy. The app allows me to compare ratings and prices of nearby vendors, and I always get the best deal. The service is prompt, and I get my clothes picked up and delivered to my doorstep – so convenient!",
      by: "Aastha Gupta",
    },
    {
      id: 2,
      testimonial:
        "Presswala Engineer is a game-changer! As someone who's always busy, I don't have time to visit the dry cleaners. This app connects me with reliable vendors for all my laundry needs, and the convenience of doorstep pickup and delivery is unbeatable. Highly recommend it!",
      by: "J.D Mehta",
    },
    {
      id: 3,
      testimonial:
        "I’ve been using Presswala Engineer for a few months now, and it’s been fantastic! The app makes it so easy to find trusted laundry service providers nearby. I love how I can choose based on their ratings and prices, ensuring I get great service every time. So happy I found this app!",
      by: "Vigyat Verma",
    },
    {
      id: 4,
      testimonial:
        "Presswala Engineer has saved me so much time! I no longer have to search for a laundry service or worry about pricing. The app is simple to use, and I can easily book a pickup for my laundry. The vendors are reliable, and the delivery is always on time. It’s everything I need in one app!",
      by: "Shilpa Kapoor",
    },
  ],
};

export const ContactContent = {
  header: "CONTACT US !",
  contactUsFor: [
    {
      id: 2,
      label: "Run Ads On App (Enquiry)",
    },
    {
      id: 3,
      label: "Become A City Partner",
    },
    {
      id: 4,
      label: "Become An Investor",
    },
    {
      id: 5,
      label: "Account Deletion ",
    },
  ],
};

export const aboutContent = {
  aboutMe: "Presswala Engineer – your go-to app for finding top-rated laundry, dry-cleaning nearby! Compare vendors, ratings, and prices to get the best deal. Skip the hassle and overpaying – download now for reliable service at your doorstep!",
  AchievementsData: [
    {
      id: 1,
      value: "Multiple",
      title: "DryCleaning Vendors",
    },
    {
      id: 2,
      value: "Premium Laundry Solution",
      title: "Affordable Prices",
    },
    {
      id: 3,
      value: "More Power",
      title: "To The Customer",
    },
    {
      id: 4,
      value: "Eco-Friendly",
      title: "Innovative",
    },
  ],
};