import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaInfoCircle, FaStore, FaHandshake } from "react-icons/fa";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import BecomePartner from "../../screens/become-partner";
import VendorOnBoardingForm from "../../screens/vendor-on-boarding";

function RenderOptionsHome() {
  const [open, setOpen] = useState(false);
  const [isAddForm, setIsAddForm] = useState(false);
  const [isVendorOnBoarding, setIsVendorOnBoarding] = useState(false);

  return (
    <div className="lg:hidden">
      <motion.div
        className="fixed bottom-6 right-6 bg-blue-600 text-white p-5 rounded-full shadow-lg cursor-pointer"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => setOpen(true)}
      >
        <FaInfoCircle size={24} />
      </motion.div>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle className="text-center">Join Us</DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-4 ">
            <motion.button
              onClick={() => {
                setIsVendorOnBoarding(true);
                setOpen(false);
              }}
              className="flex items-center gap-3 p-4 bg-green-500 text-white rounded-lg shadow-md hover:bg-green-600"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <FaStore size={20} /> Register as Vendor
            </motion.button>
            <motion.button
              onClick={() => {
                setOpen(false);
                setIsAddForm(!isAddForm);
              }}
              className="flex items-center gap-3 p-4 bg-purple-500 text-white rounded-lg shadow-md hover:bg-purple-600"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <FaHandshake size={20} /> Become a Partner
            </motion.button>
          </div>
        </DialogContent>
      </Dialog>
      <BecomePartner
        isVisible={isAddForm}
        onClose={() => setIsAddForm(false)}
      />
      <VendorOnBoardingForm 
        isVisible={isVendorOnBoarding}
        onClose={() => setIsVendorOnBoarding(false)}
      />
    </div>
  );
}

export default RenderOptionsHome;
