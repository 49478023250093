import { motion } from "framer-motion";
import { BsChevronLeft } from "react-icons/bs";
import VendorOnBoardingForm from "../../screens/vendor-on-boarding";
import { useState } from "react";

const RegisterVendor = () => {
  const [isVendorOnBoarding, setIsVendorOnBoarding] = useState(false);
  return (
    <>
      <VendorOnBoardingForm
        isVisible={isVendorOnBoarding}
        onClose={() => setIsVendorOnBoarding(false)}
      />
      <motion.div
          onClick={() => setIsVendorOnBoarding(true)}
        className="bg-white px-5 py-1 rounded-lg items-center flex gap-3 mt-20 cursor-pointer transition-colors"
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 100,
          damping: 15,
          duration: 0.8,
        }}
        whileHover={{
          scale: 1.05,
          backgroundColor: "#3b82f6",
          color: "#fff",
        }}
      >
        <motion.div
          className="p-1 rounded-full bg-blue-600"
          whileHover={{
            scale: 1.1,
            backgroundColor: "#2563eb",
          }}
          transition={{ type: "spring", stiffness: 300, duration: 0.4 }}
        >
          <BsChevronLeft className="text-white" />
        </motion.div>
        <p className="font-medium text-lg">Register your self as vendor</p>
      </motion.div>
    </>
  );
};

export default RegisterVendor;
