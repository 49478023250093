import React, { useState } from "react";
import { TiSocialFacebook } from "react-icons/ti";
import { HomeContent } from "../../constants/content";
import { BsInstagram, BsTelephone } from "react-icons/bs";
import DownloadPopUp from "./DownloadPopUp";

function Header() {
  const [openDownload, setOpenDownload] = useState(false);
  return (
    <div
      className="
    
    flex
    place-content-end
    h-[40px]
    items-center
    md:h-[50px]
    bg-white
    w-screen
    "
    >
      <div className="px-1 border-r h-full justify-center  items-center flex border-r-gray-200 border-l border-l-gray-200">
        <p
          className="cursor-pointer shortScreen:text-[13px] text-center smallestScreen:text-[10px]"
          onClick={() =>
            // (window.location.href = `https://play.google.com/store/apps/details?id=com.presswalaengineer`)
            setOpenDownload(true)
          }
        >
          Download App
        </p>
      </div>
      <div className="flex flex-row px-2 justify-between">
        <a
          target="_blank"
          href="https://www.facebook.com/people/Presswala-Engineer/61556516655268/"
          className="mr-4"
        >
          <TiSocialFacebook size={26} color={"#1193FD"} />
        </a>
        <a target="_blank" href="https://www.instagram.com/presswalaengineer?igsh=Z3lqNWx5eGVyZXkw&utm_source=qr">
          <BsInstagram size={26} color={"#1193FD"} />
        </a>
      </div>
      <div
        className={` bg-tertiary ml-5  px-2 h-full justify-center items-center flex rounded-sm cursor-pointer`}
      >
        <a
          href={"tel:" + HomeContent.contactNumber}
          className="text-center text-primary smallestScreen:text-[10px] shortScreen:text-[14px] flex items-center justify-center gap-2"
        >
          <BsTelephone />
          {HomeContent.contactNumber}
        </a>
      </div>
      <DownloadPopUp
        isVisible={openDownload}
        onClose={() => setOpenDownload(false)}
      />
    </div>
  );
}

export default Header;
