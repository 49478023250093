import { useState } from "react";
import Swal from "sweetalert2";
import { useAuth } from "./useAuth";
export const useForm = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    vendorName: "",
  });
  const { signUp, handleSuccessPopup } = useAuth();

  const [errors, setErrors] = useState({});

  const validate = () => {
    let newErrors = {};
    if (!formData.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Invalid email format";

    if (!formData.password) newErrors.password = "Password is required";
    else if (formData.password.length < 6)
      newErrors.password = "Password must be at least 6 characters";

    if (!formData.vendorName) newErrors.vendorName = "Vendor name is required";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setLoading(true);
    try {
      await signUp(formData.email, formData.password);
      setFormData({ email: "", password: "", vendorName: "" });
      setErrors({});
      handleSuccessPopup();
    } catch (error) {
      let errorMsg;
      if (error?.response?.data?.error?.code === 400 && error?.response?.data?.error?.message === "EMAIL_EXISTS") {
        errorMsg = 'This email has already been used, try a different email';
      } else {
        errorMsg = '"Something went wrong please try again after some time';
      }
      Swal.fire({
        title: "Oops!",
        text: errorMsg,
        icon: "warning",
      });
    }
    setLoading(false);
  };

  return {
    formData,
    errors,
    handleChange,
    handleSubmit,
    loading
  };
};
