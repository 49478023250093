import { useState } from "react";
import Swal from "sweetalert2";
import { apiSendPartnerMail } from "../../api/partner.api";

export function usePartnerForm({onClose}) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    agreement: false,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validate = () => {
    let newErrors = {};

    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    else if (!/^\S+@\S+\.\S+$/.test(formData.email))
      newErrors.email = "Invalid email format";

    if (!formData.phone.trim()) newErrors.phone = "Phone number is required";
    else if (!/^\d{10}$/.test(formData.phone))
      newErrors.phone = "Invalid phone number";

    if (!formData.address.trim()) newErrors.address = "Address is required";

    if (!formData.agreement) newErrors.agreement = "You must agree to invest";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setLoading(true);
    try {
      await apiSendPartnerMail({
        email: formData.email,
        subject:`Become A Partner Request | ${formData.name}`,
        text: `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${
          formData.phone
        }\nAddress: ${formData.address}\n Can invest 1.5 - 2 lakhs : ${
          formData.agreement ? "Yes" : "No"
        }`,
      });
      setFormData({
        name: "",
        email: "",
        phone: "",
        address: "",
        agreement: false,
      });
      setErrors({});
      onClose();
      Swal.fire({
        title: "Successfully",
        text: "We have received your enquiry. Hang on! we will get in touch soon.",
        icon: "success",
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Oops!",
        text: "Something went wrong please try again after some time",
        icon: "warning",
      });
    }
    setLoading(false);
  };

  return { formData, errors, handleChange, handleSubmit, loading };
}
