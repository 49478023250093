import React from 'react'
import FAQ from '../../components/faq/faq-component'
const customerFAQs = [
  { question: "What is Presswala Engineer?", answer: "Presswala Engineer is a platform that connects you with multiple trusted laundry vendors for your dry cleaning needs. We make it easier for you to select and book services through our mobile app, giving you the freedom to choose the best vendor for your needs." },
  { question: "How do I place an order?", answer: "Orders can only be placed through our mobile app. Simply download the app, browse through available vendors, select the services you need, and place your order directly from your phone." },
  { question: "Can I choose which laundry vendor to use?", answer: "Yes! Presswala Engineer offers you a variety of laundry vendors, each with their own pricing, services, and customer ratings. You can choose the one that best fits your needs and preferences." },
  { question: "What types of laundry services are available?", answer: "Our partner vendors offer a range of services, including dry cleaning, washing, ironing, and special treatments for delicate fabrics. The exact services available will vary by vendor." },
  { question: "How do I know which vendor to choose?", answer: "Each vendor on our platform has a detailed profile showcasing their services, pricing, and customer reviews. You can review this information to make an informed choice." },
  { question: "Can I track my order?", answer: "Yes! Once you've placed an order, you can track its status directly within the mobile app. You'll be notified of any updates or changes to your order, from pickup to delivery." },
  { question: "What happens if I have a problem with my vendor's service?", answer: "Presswala Engineer facilitates the connection between you and the vendor. We do not take responsibility for issues that arise between you and the vendor, including delays, quality concerns, or pricing disputes. Please reach out directly to the vendor to resolve any problems." },
  { question: "How do I pay for my laundry service?", answer: "Payment is processed securely through the mobile app, using your preferred payment method. You can choose to pay via credit card, debit card, or other available payment options within the app." },
  { question: "Is there a minimum or maximum order size?", answer: "The minimum and maximum order size may vary depending on the vendor you choose. This information will be displayed when placing your order within the app." },
  { question: "What areas does Presswala Engineer serve?", answer: "We serve a wide range of areas. The availability of vendors will depend on your location, and you can check available services within the app based on your address." },
  { question: "Can I cancel my order?", answer: "Cancellation policies may vary by vendor. Please refer to the vendor's terms and conditions for details about cancellation and refund policies." },
  { question: "How can I contact customer support?", answer: "If you have any questions or issues, you can contact our customer support team through the app or email. We'll do our best to assist you, though any vendor-specific concerns should be directly addressed with the vendor." },
  { question: "Is my personal and payment information secure?", answer: "Yes! We use industry-standard encryption to protect your personal and payment information, ensuring your data remains safe at all times." },
  { question: "Do I need to sign up for an account to place an order?", answer: "Yes, you need to create an account through our mobile app to place an order. Signing up helps us provide you with personalized services and track your orders." },
];

const vendorFAQs = [
  { question: "How do I become a vendor on Presswala Engineer?", answer: "To become a vendor, you need to register through the Presswala Engineer vendor onboarding process. Once registered, we’ll review your business and services, and if approved, you can start accepting orders from customers via our app." },
  { question: "What are the requirements to become a vendor?", answer: "We require vendors to have an established laundry business with the capacity to handle orders. You must provide details about your services, pricing, and operational standards to be considered. Additionally, vendors must maintain a high standard of customer service and timely deliveries." },
  { question: "How do I receive orders from customers?", answer: "Orders are placed directly through the Presswala Engineer mobile app. When a customer selects your services, you will receive a notification via the app. You can then accept or decline the order based on your availability." },
  { question: "Is there a fee to join Presswala Engineer as a vendor?", answer: "Presswala Engineer may charge a commission on each order placed through the platform. The exact fee structure will be outlined during the onboarding process. Please refer to our vendor agreement for full details." },
  { question: "How do I manage my pricing and services?", answer: "Vendors can update their pricing, services, and availability directly through the vendor portal within the Presswala Engineer platform. You can adjust your offerings based on the demand and services you wish to provide." },
  { question: "Can I set my own operating hours?", answer: "Yes, you can set your own operating hours within the app. Ensure that you update your availability in the vendor portal so customers are aware of your working hours." },
  { question: "How do I receive payment for the services I provide?", answer: "Payments for your services are processed through the Presswala Engineer platform. Once a customer completes an order and payment, the funds (minus the commission) will be transferred to your designated account. Payment schedules and methods will be detailed in the vendor portal." },
  { question: "How can I get more orders?", answer: "The number of orders you receive depends on your services, pricing, customer reviews, and availability. To increase your chances of receiving more orders, provide exceptional service, maintain competitive pricing, and ask satisfied customers for positive reviews." },
  { question: "Can I leave the platform anytime?", answer: "Yes, you can leave the platform at any time. To do so, simply contact Presswala Engineer's support team. Please note that any outstanding orders must be fulfilled before deactivation." },
];


function FAQScreen() {
  return (
    <div className='min-h-screen w-full'>
      <FAQ 
        id={'customer-app'}
        faqList={customerFAQs}
        headerTitle={'Customer FAQs'}
      />
      <FAQ 
        id={'vendor-app'}
        faqList={vendorFAQs}
        headerTitle={'Vendor FAQs'}
      />
    </div>
  )
}

export default FAQScreen