import React from "react";

function PWETermsAndCondition() {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-10">
      <h1 className="text-2xl font-bold text-center mb-4">Terms & Conditions</h1>
      <p className="text-gray-600 mb-4">
        These terms and conditions apply to the PWEWorkshop app ("Application") for
        mobile devices that was created by the Service Provider as a Free
        service.
      </p>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Agreement to Terms</h2>
        <p className="text-gray-600">
          By downloading or using the Application, you agree to comply with these terms.
          Unauthorized copying, modification, or extracting source code is prohibited.
        </p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Intellectual Property</h2>
        <p className="text-gray-600">
          All trademarks, copyrights, database rights, and other intellectual property
          rights related to the Application remain the property of the Service Provider.
        </p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Service Modifications</h2>
        <p className="text-gray-600">
          The Service Provider reserves the right to modify the Application or charge for its services at any time.
        </p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">User Responsibilities</h2>
        <p className="text-gray-600">
          Users are responsible for maintaining the security of their device and access to the Application.
          Jailbreaking or rooting the device is discouraged as it may compromise security and functionality.
        </p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Third-Party Services</h2>
        <p className="text-gray-600">
          The Application may use third-party services with their own terms and conditions, such as Google Play Services.
        </p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Network & Data Charges</h2>
        <p className="text-gray-600">
          The Service Provider is not responsible for any data charges incurred while using the Application.
          Users should be aware of their mobile network provider's terms when accessing the Application outside of Wi-Fi.
        </p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Limitation of Liability</h2>
        <p className="text-gray-600">
          The Service Provider does not accept liability for any loss resulting from reliance on the Application's functionality.
        </p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Updates and Termination</h2>
        <p className="text-gray-600">
          The Service Provider may update the Application or cease its operation without prior notice. Users agree to accept updates when offered.
        </p>
      </section>
      
      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Changes to Terms</h2>
        <p className="text-gray-600">
          These terms and conditions may be updated periodically. Users should review this page regularly for updates.
        </p>
      </section>
      
      <section className="text-center mt-6">
        <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
        <p className="text-gray-600">Email: customercare@presswalaengineer.com</p>
      </section>
    </div>
  );
}

export default PWETermsAndCondition;
