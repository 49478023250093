import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Main/Home";
import About from "./Main/About";
import Navbar from "../components/common/Navbar";
import Header from "../components/common/Header";
import Contact from "./Main/Contact";
import Footer from "../components/common/Footer";
import Page404 from "../components/UI/Page404";
import Cost from "./Main/Cost";
import TermsConditions from "./Main/TermsConditions";
import PrivacyPolicy from "./Main/PrivacyPolicy";
import PWEPrivacy from "./privacy/pwe-privacy";
import PWETermsAndCondition from "./privacy/pwe-terms-condition";
import FAQScreen from "./Main/FAQ";
function MainIndex() {
  const location = useLocation();
  return (
    <>
      <Header />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/cost" element={<Cost />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/pwe-terms-and-condition" element={<PWETermsAndCondition />} />
        <Route path="/pwe-privacy-policy" element={<PWEPrivacy />} />
        <Route path="/faq" element={<FAQScreen />} />
        <Route path="test" element={<Test />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
      {location.pathname !== "/" && <Footer />}
    </>
  );
}

function Test() {
  return <div>test</div>;
}

export default MainIndex;
